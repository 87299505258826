<!-- 心理咨询配置 -- 咨询周期设置 -- 查看 -->
<template>
  <div>
    <div class="form-body" style="padding-top: 35rem;padding-left: 60rem">
      <h4>咨询基础配置</h4>
      <br>
      <el-form ref="form" label-width="100rem">
        <el-form-item label="名称" prop="name">
          <p>{{dataCont.term_name}}</p>
        </el-form-item>
        <el-form-item label="校区" prop="school_id">
          <p>{{dataCont.school_name}}</p>
        </el-form-item>
        <div class="flex">
          <el-form-item label="咨询周期" prop="start_time">
            <p>{{dataCont.start_at}} - {{dataCont.end_at}}</p>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="footer-button">
      <el-button type="primary" @click="$router.back()">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
	_config:{"route":{"path":"details","meta":{"title":"查看"}}},
  data() {
    return {
      dataCont: ''
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$_axios2.get('api/evaluation/counseling-setting/term-details?id=' + this.$route.query.id).then(res => {
        console.log(res)
        if (res.data.status === 0) {
          console.log(res.data.data)
          this.dataCont = res.data.data
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-form .el-input {
  width: 250rem !important;
}
</style>
